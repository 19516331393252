.post {
    background-color: #343a40;
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
    padding: 1rem;
    text-align: center;
    margin-top:2%;
}

.posts {
    margin :auto;
    height:75%;
    top:30%;
}


.blogContainer{
    width: 100%;
    height:95vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    margin:auto;
    padding:3%;
}

.blogContainer::-webkit-scrollbar {
    display:none;
}

.post-username {
    font-family: 'source-code-pro', sans-serif;
    font-size:2rem;
    font-weight: bolder;
    color:black;
    padding-bottom: 0;
    margin:0;
}



.post-header {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    background-color: rgba(185, 185, 185, 0.51);
}

.post-tag {
color:blue;
    font-family: 'Source Sans 3', sans-serif;
        text-align: center;
    margin-bottom:1%;
    margin-top:2%;
    padding:0;
    font-weight: bolder;
    font-size: 1vh;
    word-wrap: break-word;
}

.post-message {
    font-family: 'Garamond', sans-serif;
    font-size: 1rem;
    color: #000000;
    font-weight: bolder;
    text-align: center;
    background-color: rgba(211, 211, 211, 0.51);
}

.post-timestamp {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 0.7rem;
    font-style: oblique;
    font-weight: normal;
    text-align: center;
    padding-bottom: 1em;
}

img.post-image {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    background-color: rgba(241, 241, 241, 0.51);
    border: 2px solid #000000;
    border-radius: 15px;
    padding:1em;
}

.tag-display{
    color:black;
    margin-top:5em;
    margin-bottom: 0;
    text-align: center;
    justify-content: center;
}

.post-image {
   position: relative;
    height:15%;
    width:60%;
    margin:1%;
}

.post-content {
    border-width: 1px;
    border-style: solid;
    border-color: black;
    width:85%;
    margin-left: 7.5%;
    background-color: rgba(185, 185, 185, 0.51);
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
}
