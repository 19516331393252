*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


*, html {
  scroll-behavior: smooth !important;
}

body {
  overflow: hidden;
  scroll-behavior: smooth;
  background: linear-gradient(-45deg, #4b4b4b, #070707);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}



.vr {
  height: 4vh;
  width:1px;
  background-color:gray;
  margin-right: 10px;
  margin-left: 10px;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.blogsMinimized {
  width:40vh;
  height:40vh;
  margin: 5vh auto 15vh;
  background-color: #a8a8a8;
}

.projectsMinimized {
  width:40vh;
  height:37vh;
  margin:auto;
  margin-top: 5vh;
  background-color: rgba(168, 168, 168, 0.07);
  border-radius: 10px;
  padding:15px;
  display: flex;
  flex-direction: column;
  font-family:  "Source Sans 3", serif;
  color:white;
}

.projectsMinimized h4 {
  font-family:  "Source Sans 3", serif;
  color:white;
  background-color: rgba(128, 128, 128, 0.35);
  border-radius: 15px;
  padding:0.5vh;
  box-shadow: 3px 2px #000000;
}

.projectSingleMinimized {
  display: flex;
  justify-content: space-between;
  background-color: rgba(138, 138, 138, 0.32);
  width:95%;
  height:8vh;
  margin: 1.5vh auto auto;
  border-radius: 15px;
  padding:5px;
  border: solid 1px black;
  box-shadow: 1px 2px #000000;
}

.textInfoSection {
  margin:auto;
  height:100%;
  margin-top:0.25vh;
}

.textInfoSection h5{
  margin:0.3vh;
}

.projectSingleMinimized a {
  padding: 1.5vh;
  border-radius: 100px;
  align-self: flex-end;
  color:white;
  margin-top:auto;
  margin-bottom:auto;
  border: solid 1px #494949;

}

.projectSingleMinimized img {
  align-self: flex-start;
  width:2.5vh;
}





.links {
  position: relative;
  text-align: center;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.links a{
  text-shadow: 2px 2px #000000;
}


.listProjects {
  float:right;
  width:30%;
}

.header h2{
  color:white;
  text-shadow: 2px 3px #000000;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 3000;
  text-align: center;
  margin-bottom: 1vh;
  font-size: 4.1vh;
}



a.btn.btn-primary.btn-lg.active {
  margin:0.25vh;
  padding:1vh;
  font-family: "Source Sans 3", serif;
  font-weight: bolder;
  font-size: 100%;
  scale: 80%;
  border-width: 2px;
  border-style: solid;
  border-color: blue;
  -webkit-border-radius: 10px;
  -moz-border-radius: 0px;
  border-radius: 50px;
  background-color: #949494;
  -webkit-box-shadow: 0 0 5px rgba(31,31,31,.2),
  1px 2px 1px rgba(224,224,224,.65),
  inset 25px 5px 0px rgba(31,31,31,.6),
  inset 40px 15px 25px rgba(224,224,224,.25);
  -moz-box-shadow: 0 0 20px rgba(31,31,31,.2),
  3px 7px 25px rgba(224,224,224,.65),
  inset 5px 25px 10px rgba(31,31,31,.6),
  inset 5px 0 50px rgba(224,224,224,.25);
  box-shadow: 0 0 20px rgba(31,31,31,.2),
  1px 1px 5px rgba(224,224,224,.65),
  inset 1px 1px 10px rgba(31,31,31,.6),
  inset 0 0 5px rgba(224,224,224,.25);
}


.header {
  background-color: rgba(24, 24, 24, 0.6);
  overflow: hidden;
  z-index: 2;
  margin: auto;
  padding:0.5vh;
  width: 100%;
  justify-content: center;
  text-align: center;
  position: sticky;
  top:0;
}

.contentButton {
  padding:1.5vh;
  background-color: #545454;
  border-radius: 15px;
  border: solid 1px black;
  margin:2vh;
  color: white;
  box-shadow: 1px 0px 4px 1px #000000;
}

.container{
  width: 100%;
  height:100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  margin:auto;
}

.container::-webkit-scrollbar {
  display:none;
}

.LanguageIcons {
  margin-top: 4vh;
}

.LanguageIcons img {
  width:8vh;
  height:8vh;
  padding-left: 15px;
  border-radius: 15px;
  padding: 1.5vh;
  background-color: #5d5d5d;
  margin-right: 2vh;
  -webkit-border-radius: 10px;
  -moz-border-radius: 0px;
  border-radius: 50px;
  background-color: #949494;
  -webkit-box-shadow: 0 0 5px rgba(31,31,31,.2),
  1px 2px 1px rgba(224,224,224,.65),
  inset 25px 5px 0px rgba(31,31,31,.6),
  inset 0 15px 25px rgba(224,224,224,.25);
  -moz-box-shadow: 0 0 20px rgba(31,31,31,.2),
  1px 2px 4px rgba(224,224,224,.65),
  inset 5px 25px 10px rgba(31,31,31,.6),
  inset 5px 0 50px rgba(224,224,224,.25);
  box-shadow: 0 0 20px rgba(31,31,31,.2),
  1px 1px 5px rgba(224,224,224,.65),
  inset 1px 1px 10px rgba(31,31,31,.6),
  inset 0 0 5px rgba(224,224,224,.25);
}

.projectHeader h5 {
  float:left;

}

.projectHeader img {
  float:right;
  margin:1%;
}

.projectHeader {
  height:7vh;
  width:100%;
  margin: auto;
}



h2 {
  font-size: 1vh;
}

h5 {
  color: #a8a8a8;
  font-weight: bold;
}

.portfolioHeader {
  margin-top:8vh;
  margin-bottom: 3vh;
}

.portfolioHeader h5 {
  text-align: center;
  font-family:  "Source Sans 3", serif;
}

.ContentSection {
  margin-top:5vh;
  padding:3%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(images/marble.webp);
  box-shadow: 1px 2px #000000;
  border-radius:15px;
  text-align: center;
  font-family:  "Source Sans 3", serif;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  opacity: 1;
}

.ContentSection p {
  margin:0;
  margin-top:1vh;
  font-family:  "Source Sans 3", serif;

}

.ContentSection h5 {
  text-align: center;
  color: white;
  text-shadow: -2px 4px 7px rgba(0, 0, 0, 0.5), 5px -2px 0 rgba(0, 0, 0, 0.9);
  font-size:7vh;
  font-family:  "Source Sans 3", serif;
}



.portfolioHeader h1 {
  text-align: center;
  font-family:  "Source Sans 3", serif;
  font-weight: bolder;
}


.portfolioSlide {
  margin-top:3vh;
  gap:50px;
  top:100vh;
  color:#fff;
  margin-bottom: 10vh;
}

.slides {
  width:100%;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content: center;
  gap:50px;
  color:#fff;
  scroll-snap-align: start;

}

p.introBlurb {
  color: white;
  margin:auto;
  font-family:  "Source Sans 3", serif;
}

h2 {
  color:white;
  font-size: 3vh;
}



.slides h2{
  font-size: 3vh;
  font-weight: 600;
  max-width: 100%;
  margin-bottom: 2vh;
}


.portfolioCentreContent
{
  width:100%;
  background-color: rgba(103, 103, 103, 0.93);
  padding: 2vh;
  box-shadow: -2px -3px 4px 4px #000000;
  border-radius: 10px;
}

a.navbar-brand {
  font-size: 1.5em;
  font-weight: 8000;
}

.introBlurb {
  margin: auto;
  font-size: 1.5vh;
  width: 40vh;
  background-color: rgba(47, 47, 47, 0.49);
  padding:2vh;
  border-radius: 10px;
  font-family:  "Source Sans 3", serif;
  color:white;
}

div.row.g-4.py-5.row-cols-1.row-cols-lg-3 {
  scale: 90%;
}

.spacerDiv {
  height:15vh;
}

.containerContent {
  margin-top: 5vh;
  text-align: center;
}

.languageCollection {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.languageIcon {
margin:auto;
}

.languageIcon img {
  width:4vh;
  height:4vh;
  margin-top: 4vh;
}

.languageIcon h2 {
  font-size: 2vh;
}

.flexProjects {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height:100%;
}



.projectFlexBox {
  outline : .1vh dashed green;
  padding:1em;
  margin:2vh;
max-width: 40vh;
}


.projectFlexBox p {
  text-align: left;
  margin-bottom: 1em;
}

.ContentSection a{
  font-family: Impact;
}

.socialLinks {
  display:flex;
  flex-wrap: wrap;
  justify-content: left;
  color: white;
  font-size: 1vh;


}

.projectFlexBox p {
  width:100%;
  height:100%;
}

.socialLinks img {
  margin: 1vh;
  height: 2vh;
  width:2vh;
}

.slide3 h1 {
  color:black;
  font-family: 'Source Sans 3', sans-serif;
}

.slide2 h2 {
  color: black;
  -webkit-text-stroke-color: #000000;

}

.slide4 h2 {
  color: black;
  -webkit-text-stroke-color: #000000;
}

.socialLinks a {
  filter: invert(1);
}


.footerPanel {
  position: fixed;
  bottom: 0px;
  width:100%;
  height:4vh;
  flex: auto;
  background-color: rgba(0, 0, 0, 0.44);
}

.footerPanel p {
  font-family: 'Source Sans 3', sans-serif;
  position: absolute;
  bottom: 0%;
  right: 0%;
  font-size: 50%;
  margin: 5px;
  color:white;
}









